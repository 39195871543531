<template>
	<!-- 九宫格编辑 -->
	<div class="marketingLotteryEdit">
		<div class="el-content lottery-container">
			<div class="lottery-container-left">
				<div class="lcl-model" :style="bg"></div>
				<div class="lcl-icon" v-if="form.data && form.data.data && form.data.data.length>0">
					<div class="lcl-icon-item">
						<a-image class="lcl-icon-item-img" :src='form.data.data[0].img_url'/>
					</div>
					<div class="lcl-icon-item">
						<a-image class="lcl-icon-item-img" :src='form.data.data[1].img_url'/>
					</div>
					<div class="lcl-icon-item">
						<a-image class="lcl-icon-item-img" :src='form.data.data[2].img_url'/>
					</div>
					<div class="lcl-icon-item">
						<a-image class="lcl-icon-item-img" :src='form.data.data[3].img_url'/>
					</div>
					<div class="lcl-icon-item"></div>
					<div class="lcl-icon-item">
						<a-image class="lcl-icon-item-img" :src='form.data.data[4].img_url'/>
					</div>
					<div class="lcl-icon-item">
						<a-image class="lcl-icon-item-img" :src='form.data.data[5].img_url'/>
					</div>
					<div class="lcl-icon-item">
						<a-image class="lcl-icon-item-img" :src='form.data.data[6].img_url'/>
					</div>
					<div class="lcl-icon-item">
						<a-image class="lcl-icon-item-img" :src='form.data.data[7].img_url'/>
					</div>
				</div>
			</div>
			
			<div class="lottery-container-right">
				<a-card title="奖品设置">
					<a-form :label-col="{ span: 3 }" :wrapper-col="{ span: 16 }">
						<a-tabs v-model:activeKey="current" tab-position="left">
							<a-tab-pane v-for="(item,index) in form.data.data" :key="index" :tab="'奖品 '+(index+1)">
								<a-form-item label="奖品选择" required>
								    <a-radio-group v-model:value="item.Prizetype">
								      <a-radio :value="1">谢谢参与</a-radio>
								      <a-radio :value="2">余额红包</a-radio>
								      <a-radio :value="3">优惠券</a-radio>
								      <a-radio :value="4">积分</a-radio>
								      <a-radio :value="5">赠品</a-radio>
								    </a-radio-group>
								</a-form-item>
								<a-form-item label="名称">
									<a-input v-model:value="item.title"></a-input>
								</a-form-item>
								<a-form-item>
									<template #label>
										<span>权重</span>
										<a-tooltip totle="权重越小，概率越小 ，反之权重越大,概率越大">
											<i class="ri-information-fill"></i>
										</a-tooltip>
									</template>
									<a-input v-model:value="item.probability"></a-input>
								</a-form-item>
								<a-form-item label="图片选择">
									<kd-img-select :src="item.img_url" @change="(url)=>{ item.img_url = url }"></kd-img-select>
								</a-form-item>
								<a-form-item label="红包金额"  v-if="item.Prizetype == 2">
									<a-input-number v-model:value="item.value" :min="0"/>
								</a-form-item>
								<a-form-item label="积分数量" v-if="item.Prizetype == 4">
									<a-input v-model:value="item.value"></a-input>
								</a-form-item>
								<a-form-item label="优惠券" v-if="item.Prizetype == 3">
									<a-select v-model:value="item.good_id" placeholder="请选择">
										<a-select-option value="0" label="请选择"></a-select-option>
									    <a-select-option v-for="val in coupon"
									      :key="val.id"
									      :value="val.id">{{val.coupon_name}}
									    </a-select-option>
									</a-select>
								</a-form-item>
								<a-form-item label="商品" v-if="item.Prizetype == 5">
									<a-select
										show-search
										v-model:value="item.good_id"
										:defaultActiveFirstOption="false"
										placeholder="请输入商品名称"
										:show-arrow="false"
										:filter-option="false"
										:not-found-content="null"
										@search="remoteMethod"
									>
										<a-select-option v-for="(item, index) in goods" :key="index" :value="item.id">{{ item.goods_name }}</a-select-option>
									</a-select>
								</a-form-item>
								<a-form-item label="商品规格" v-if="item.Prizetype == 5 && spec.length >0" >
									<a-radio-group v-model:value="item.good_space">
									  <a-radio :value="val.id" v-for="val in spec" :key="val.id">
											<span v-for="spec in val.sku" :key="spec.id">{{spec.spec_value}};</span>
									  </a-radio>
									</a-radio-group>
								</a-form-item>
								<a-form-item label="库存" v-if="item.Prizetype != 1">
									<a-input v-model:value="item.nums"></a-input>
								</a-form-item>
							</a-tab-pane>
						</a-tabs>
						
						<a-form-item label="抽奖时间">
							<a-range-picker show-time v-model:value="time" format="YYYY-MM-DD HH:mm:ss"></a-range-picker>
						</a-form-item>
						<a-form-item label="抽奖类型">
							<a-radio-group v-model:value="form.type">
							    <a-radio :value="1">总共抽奖次数</a-radio>
							    <a-radio :value="2">每天可抽奖次数</a-radio>
							</a-radio-group>
						</a-form-item>
						<a-form-item>
							<template #label>
								<span>抽奖次数</span>
								<a-tooltip title="抽奖次数">
									<i class="ri-information-fill"></i>
								</a-tooltip>
							</template>
							<a-input v-model:value="form.frequency"></a-input>
						</a-form-item>
						<a-form-item>
							<template #label>
								<span>消耗积分</span>
								<a-tooltip title="每次抽奖消耗积分数量">
									<i class="ri-information-fill"></i>
								</a-tooltip>
							</template>
							<a-input v-model:value="form.points"></a-input>
						</a-form-item>
						<a-form-item label="是否默认">
							<a-radio-group v-model:value="form.is_default">
							    <a-radio :value="1">是</a-radio>
							    <a-radio :value="2">否</a-radio>
							</a-radio-group>
						</a-form-item>
						<a-form-item label="抽奖规则">
							<tinymce-editor v-model="form.text"></tinymce-editor>
						</a-form-item>
						<a-form-item  :wrapper-col="{ offset: 3 }">
							<a-space>
								<a-button type="primary" @click="submitSave">保存</a-button>
								<a-button @click="$router.go(-1)">返回</a-button>
							</a-space>
						</a-form-item>
					</a-form>
				</a-card>
			</div>
		</div>
		
	</div>
</template>

<script>
import moment from 'moment';
import { computed, reactive, toRefs } from 'vue';
import { useRoute } from 'vue-router';
import lotteryModel from '@/api/addons/lottery';
import setModel from '@/api/set';
import couponModel from '@/api/addons/coupon';
import goodsModel from '@/api/goods';
export default{
	setup(){
		const state = reactive({
			id:0,
			current:0,
			form:{
				id:0,
				data:{data:[]},
				start_time:'',
				end_time:'',
				frequency:'',
				type:'',
				text:'',
				points:0,
				is_default:2,
			},
			time:[],
			coupon:[],
			goods:[],
			spec:[],
			lottery_bg:'',
		})

		const opt = useRoute().query
		if( opt.id ){
			lotteryModel.getLotteryDetail(opt.id,res=>{
				state.form.id = opt.id
				state.form.data = JSON.parse(res.data)
				state.form.type = res.type
				state.form.text = res.text
				state.form.points = res.points
				state.form.end_time = res.end_time
				state.form.frequency = res.frequency
				state.form.start_time = res.start_time
				state.form.is_default = res.is_default
				state.time = [moment(res.start_time,"YYYY-MM-DD HH:mm:ss"),moment(res.end_time,"YYYY-MM-DD HH:mm:ss")]
			})
		}else{
			for( let i=0;i<8;i++ ){
				let data = {
					Prizetype:1,
					img_url:"",
					title:"",
					value:0,
					nums:0,
					good_id:"",
					good_type:"",
					good_space:0,
					probability:0,
					md5: Math.ceil( Math.random() * 1000000 )
				}
				state.form.data.data.push(data)
			}
			
		}
		setModel.getFarmSet(['lottery_bg']).then(res=>state.lottery_bg = res.lottery_bg)
		couponModel.getCoupon(1,999,null,res=>state.coupon = res.list)

		const bg = computed(()=>{
			return `background-image: url(${state.lottery_bg});background-size: cover;background-position: center center;`
		})

		function submitSave(){
			let form = JSON.parse(JSON.stringify(state.form))
			if( state.time.length > 0){
				form.start_time = moment(state.time[0]).format("YYYY-MM-DD HH:mm:ss")
				form.end_time = moment(state.time[1]).format("YYYY-MM-DD HH:mm:ss")
			}
			lotteryModel.addOrEditLottery(form)
		}

		function remoteMethod(e){
			goodsModel.getGoodsList(1,999,{key:e},res=>{
				state.goods = res.list
				state.spec = []
			})
		}

		return{
			...toRefs(state),
			submitSave,
			remoteMethod,
			bg
		}
	}
}

</script>

<style lang="scss">
	.lottery-container{
		display: flex;
		&-left{
			width: 375px;
			height: 660px;
			background: #fff;
			border-radius: 30px;
			padding: 40px 12.5px;
			box-shadow: 10px 10px 10px #F4F4F4;
			border: 1px solid #f4f4f4;
			position: relative;
			overflow: hidden;
			.lcl-model{
				width: 100%;
				height: 660px;
			}
			
			.lcl-icon{
				position: absolute;
				width: 75%;
				margin-left: 13%;
				top: 53%;
				height: 33%;
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;
				
				&-item{
					width: 26.3%;
					height: 23%;
					padding: 10px;
					&-img{
						width: 100%;
						height: 100%;
					}
				}
			}
			
			
		}
	
		&-right{
			margin-left: 20px;
			width: 70%;
		}
	}
</style>
